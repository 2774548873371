import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from './axios'
import store from './store'
import Vue3MarkdownIt from 'vue3-markdown-it'
import Home from './views/portal/Home1.vue'
import dayjs from 'dayjs'
import './styles/common.scss'
import _ from 'lodash'
import md5 from 'js-md5'
import website from './config/website'
import router from './router'
import i18n from './lang/'
import { language, messages } from './lang/'

// 创建应用实例
const app = createApp(Home)

// 配置全局属性
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.website = website
app.config.globalProperties._ = _
app.config.globalProperties.$md5 = md5

// 配置 axios
window.axios = axios

// 使用必要的插件
app.use(ElementPlus, {
  locale: messages[language]
})
app.use(store)
app.use(router)
app.use(i18n)

// 注册必要的组件
app.component('vue3-markdown-it', Vue3MarkdownIt)

// 挂载应用
app.mount('#portal') 