import request from '@/axios';

export const getList = (current, size, params) => {
  return request({
    url: '/sc-llm/firmProject/list',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    }
  })
}

export const getListWithJoin = (current, size, firmProject) => {
  return request({
    url: '/sc-llm/firmProject/listWithJoin',
    method: 'get',
    params: {
      ...firmProject,
      current,
      size,
    }
  })
}


export const getDetail = (id) => {
  return request({
    url: '/sc-llm/firmProject/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const searchProjectByEntIdAndName = (enterpriseId, projectName) => {
  return request({
    url: '/sc-llm/firmProject/searchProjectByEntIdAndName',
    method: 'get',
    params: {
      enterpriseId,
      projectName
    }
  })
}

export const searchProjectByName = (projectName) => {
  return request({
    url: '/sc-llm/firmProject/searchProjectByName',
    method: 'get',
    params: {
      projectName
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/sc-llm/firmProject/remove',
    method: 'post',
    params: {
      ids,
    }
  })
}

export const add = (row) => {
  return request({
    url: '/sc-llm/firmProject/save',
    method: 'post',
    data: row
  })
}

export const update = (row) => {
  return request({
    url: '/sc-llm/firmProject/update',
    method: 'post',
    data: row
  })
}

export const getProjectFiles = (projectId) => {
  return request({
    url: '/sc-llm/firmProject/listProjectFinancialInfoFiles',
    method: 'get',
    params: {
      projectId
    }
  })
}


export const getKnowledgeList = (isSystem) => {
  return request({
    url: '/sc-llm//llmKnowledge/list',
    method: 'get',
    params: {
      isSystem:isSystem
    }
  })
}


export const getWorkingPaperFirmFiles = (firmId,taxProjectCategory) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/listWorkingPaperFirmFiles',
    method: 'get',
    params: {
      firmId  :firmId,
      taxProjectCategory:taxProjectCategory
    }
  })
}


export const postCustomFile = (formData) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/addCustomAttachments',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const postCustomSheet = ( formData) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/addCustomSheet',
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const getCustomSheet = ( parentFileId,sheetIndex) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/customSheetContent',
    method: 'get',
    params: {
      parentFileId,
      sheetIndex
    }
   
  })
}
export const getCustomAllSheet = (parentFileId) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/customSheetAllContent',
    method: 'get',
    params: {
      parentFileId 
    }
  })
}

export const getBlankPaperFirmFiles = (firmId, taxProjectCategory=1) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/listWorkingPaperFirmFiles',
    method: 'get',
    params: {
      firmId,
      taxProjectCategory
    }
  })
}

export const getCustomAttachFiles = (parentFileId, customType) => {
  return request({
    url: '/sc-llm/llmKnowledgeDocFiles/customAttachFiles',
    method: 'get',
    params: {
      parentFileId,
      customType
    }
  })
}

export const copyProjectFinancialInfo = (sourceProjectId, targetProjectId, enterpriseId) => {
  return request({
    url: '/sc-llm/firmProject/copyProjectFinancialInfo',
    method: 'post',
    data: {
      sourceProjectId,
      targetProjectId,
      enterpriseId
     
    }
  })
}

