<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div class="avue-layout" :class="{ 'avue-layout--horizontal': isHorizontal }" >
       <!-- 左侧导航栏 --> 
       <small-sidebar class="small-sidebar"  @toggle-sidebar="toggleMainSidebar" />
      <div class="avue-sidebar ai-sidebar sidebar-container" v-show="showMainSidebar "> 
        <div class="main-sidebar">
          <div class="icon project-title">
          # {{ $route.query.projectName }}
          </div>
          <sidebar />
        </div>
      </div>
      
      <div class="avue-main" :style="{ marginLeft: showMainSidebar ? '0px' : '60px' }"> 
        <!-- 顶部导航栏 -->
        <top ref="top" />
        <!-- 顶部标签卡 -->
        <tags ref="projectTags"/>
        <search class="avue-view" v-show="isSearch"></search>
        <!-- 主体视图层 -->
        <div id="avue-view" v-show="!isSearch" v-if="isRefresh">
          <router-view #="{ Component }">
            <keep-alive :include="$store.getters.tagsKeep">
              <component :is="Component" ref="currentComponent" />
            </keep-alive>
          </router-view>
        </div>
      </div> <!-- 其他内容 -->
    <div class="sidebar-container  bg-white" :class="{ 'sidebar-collapsed': isSidebarOpen }"> 
      <el-aside :width="sidebarWidth + 'px'" v-loading="loading" class="   ">
        <el-scrollbar  v-if="!selectedChat"> 
          <!-- 聊天历史-->
          <div class="project" >
            <div class=" bg-white flex-default jc-sb padding-10 line-30 border-bottom align-left">
              <i class="icon"><b>历史信息</b></i>
             
              <i class="iconfont iconicon_more"  @click="toggleChatWindow"></i>
            </div>
            <div class="padding-10 bg-white" >
              <div
                v-for="(item, index) in chatHistory"
                :key="index"
                class="margin-b10 fs-14 margin-b10 padding-10 border-radius8 saber-relative"
              >
                <p class="line-25 pointer">{{ item.label }}</p>
                <ul>
                  <li
                    v-for="(session, sessionIndex) in item.list"
                    :key="sessionIndex"
                    class="saber-relative"
                    :class="{ active: isActive(item, session) }"
                    @click="getChatHistory(item, session)"
                  >
                    <p class="line-30 text-line1 fs-14 fc-666 pointer">{{ session.name }}</p>
                    <div class="delete align-center text-center jc-center">
                      <i class="iconfont iconicon_delete"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
            
        </el-scrollbar>
      <!-- 显示 chatWindow 组件 -->
        <div   v-if="selectedChat" class="chat-window">
          <div class="project"  style="position: absolute;top: 0;left: 0;width: 100%;">
            <div class=" bg-white flex-default jc-sb padding-10 line-30 border-bottom align-left">
              <i class="icon"><b>历史信息</b></i>
             
              <i class="iconfont iconicon_more"  @click="toggleChatWindow"></i>
            </div>
            </div>
            <el-container  class="height-100">
              <chatWindow ref="chatWindow" :queryParam="queryParam"></chatWindow>
            </el-container>
       

        </div>
        
      </el-aside> 
      <div class="resize-handle" @mousedown="startResize" :style="{ right: resizeHandlePosition }"></div>  
    </div>
  </div>
    </div>
  
</template>

<script>
import {
  getDetail as getEntDetail,
} from '@/api/firmEnterprise/firmEnterprise';
import index from '@/mixins/index';
import wechat from './wechat.vue';
//import { validatenull } from 'utils/validate';
import { mapGetters } from 'vuex';
import tags from './projectTags.vue';
import search from './search.vue';
import { getToken } from 'utils/auth';
import { getChatSessions } from '@/api/project/chatsession';
import logo from './logo.vue';
import top from './top/projectIndex.vue';
import sidebar from './sidebar/projectIndex.vue'; 
import SmallSidebar from './sidebar/sidebarSmall.vue'; // 新导入的项目侧边栏
import { mapState, mapMutations } from 'vuex';
import { ref, onMounted } from 'vue'
import chatWindow from '@/views/aiApplication/chatWindow.vue'; // 确保路径正确

export default {
  mixins: [index],
  components: {
    top,
    logo,
    tags,
    search,
    sidebar,
    wechat, 
    SmallSidebar,
    chatWindow
  },
  name: 'projectIndex',
  provide() {
    return {
      projectIndex: this,
      triggerLuckysheetResize: () => this.triggerLuckysheetResize
    };
  }, 
  data() {
    return {
      loading: true,
      enterpriseDetail: {},
      sidebarWidth: 250,
      isSidebarCollapsed: false,
      chatHistory: [], 
      projectId:'',
      enterpriseId:'',
      selectedChat: null, // 用于存储选中的聊天记录
      queryParam: {
        // 初始化 queryParam
      },
      showMainSidebar: true, // 控制 main-sidebar 的可见性 
      triggerLuckysheetResize: () => {}
    };
  },
  watch: {
    enterpriseDetail: {
      handler(newVal) {
        // 检查对象是否为空
        if (Object.keys(newVal).length === 0) {
          this.loading = true;
        } else {
          this.loading = false;
        }
      },
      deep: true, // 深度监听，确保即使对象的属性变化也能触发
      immediate: true // 立即执行，确保在组件实例化时立即检查
    },
    $route: {
      handler() {
        this.$nextTick(() => {
          console.log('Route changed, initializing chat window');
          this.initializeChatWindow();
        });
      },
      immediate: true // 这会在组件创建时立即触发一次
    },
    sidebarWidth: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // 给一个小延迟，确保DOM已经更新
          setTimeout(() => {
            const event = new Event('resize')
            window.dispatchEvent(event)
          },100)
        }
      }
    },
    showMainSidebar: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          // 给一个小延迟，确保DOM已经更新
          setTimeout(() => {
            const event = new Event('resize')
            window.dispatchEvent(event)
          },100)
        }
      }
    }
  },
  created() {
    this.initRouteParams();
    this.Load(this.$route.query.enterpriseId);
    this.queryParam = { ...this.$route.query }; 
    this.fetchChatSessions();
  }, 
  computed: {
    ...mapGetters([
      'isHorizontal',
      'isRefresh',
      'isLock',
      'isCollapse',
      'isSearch',
      'menu',
      'setting',
      'projectTagWel'
    ]),
    ...mapState({
      isSidebarOpen: state => state.projectTags.isSidebarOpen
    }),
    validSidebar() {
      return !(
        (this.$route.meta || {}).menu === false || (this.$route.query || {}).menu === 'false'
      );
    },
    resizeHandlePosition() {
      return this.isSidebarCollapsed ? '0px' : `${this.sidebarWidth - 1}px`;
    }
  },
  props: [],
 
  methods: {
    initializeChatWindow() {
      if (this.$refs.chatWindow && this.$refs.chatWindow.initializeChat) {
        this.$refs.chatWindow.initializeChat(this.$route.query);
      } else {
        console.warn('Chat window component or initializeChat method not found');
      }
    },
    async refreshChatSessions() {
      await this.fetchChatSessions(); 
      if (this.chatHistory.length > 0 && this.chatHistory[0].list.length > 0) {
        this.selectChatSession(this.chatHistory[0],this.chatHistory[0].list[0]);
      }
    },

    selectChatSession(item,session) {
      // Navigate to the chat window with the selected session
     
      this.getChatHistory(item,session) 
    },
    initRouteParams() {
      this.projectId = this.$route.query.projectId || '';
      this.enterpriseId = this.$route.query.enterpriseId || '';
    },
    ...mapMutations(['toggleSidebar']),
    async fetchChatSessions() {
      try {
        const response = await getChatSessions(this.projectId);
        // 假设后端返回的数据结构需要转换
       const chatHistory = this.formatChatSessions(response.data.data)
       this.chatHistory = chatHistory
       
      } catch (error) {
        console.error('Failed to fetch chat sessions:', error);
        // 处理错误，例如显示一个错误消息
      }
    },
    formatChatSessions(sessions) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const twoDaysAgo = new Date(today);
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          console.error('Invalid date:', dateString);
          return 'Invalid Date';
        }
        date.setHours(0, 0, 0, 0);

        if (date.getTime() === today.getTime()) {
          return '今天';
        } else if (date.getTime() === yesterday.getTime()) {
          return '昨天';
        } else if (date.getTime() === twoDaysAgo.getTime()) {
          return '前天';
        } else {
          // 格式化为 MM-DD
          return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        }
      };

      const groupedSessions = sessions.reduce((acc, session) => {
        const formattedDate = formatDate(session.create_time);
       
        if (!acc[formattedDate]) {
          acc[formattedDate] = [];
        }
        acc[formattedDate].push(session);
        return acc;
      }, {});
      console.log(groupedSessions);
      return Object.entries(groupedSessions).map(([date, list]) => ({
        label: date,
        list: list.map(session => ({ name: session.session_name, id: session.id }))
      }));
    },
    startResize(e) {
      this.resizeStartX = e.clientX;
      this.initialSidebarWidth = this.sidebarWidth;
    
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.stopResize);
    },
    resize(e) {
      
      const dx =  window.innerWidth - e.clientX; 
      // Update the sidebar width, with min and max constraints
      //侧边栏最大宽度
      this.sidebarWidth =   Math.max(200, Math.min( dx, 1800));
      //console.log('=',window.innerWidth -e.clientX,this.sidebarWidth)
    },
    handlePreviewFile(row) {
      // 确定正确的对象，可能是 row 或 row.row
      const fileData = row.row || row;

      if (fileData.fileLink) {
        const fileName = fileData.fileName || fileData.name || '';
        const fileExtension = fileName.split('.').pop().toLowerCase();
        fileData.fileName = fileName;
        const uniqueKey = `${fileData.id}`;
        localStorage.setItem('navigationParams-' + uniqueKey, JSON.stringify({
          id: fileData.id,
          fileName:fileName,
          name: fileData.originalName,
          originalName: fileData.originalName,
          fileLink: encodeURIComponent(fileData.fileLink),
        }));
        if (['xls', 'xlsx', 'csv'].includes(fileExtension)) {
          
          console.log('navigateToLuckysheet', this.$route.meta);
          this.$router.push({
            path: '/luckysheet/index',
            query: {
              projectId: this.queryParam.projectId,
              key: uniqueKey,
              name: fileData.originalName || fileName,
              enterpriseId: this.queryParam.enterpriseId,
              projectName: this.queryParam.projectName
            }
          });
        } else {
          console.log('handlePreviewFile', fileData);
          this.$router.push({
            path: '/file-viewer/index',
            query: {
              key: uniqueKey,
              projectId: this.queryParam.projectId,
              projectName: this.queryParam.projectName,
              fileId: fileData.id,
              enterpriseId: this.queryParam.enterpriseId,
              fileName: fileData.originalName || fileData.fileName,
              name: fileData.originalName || fileData.fileName
            }
          });
        }
      }
    },
    stopResize() {
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.stopResize);
    },
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
      if (this.isSidebarCollapsed) {
        this.sidebarWidth = 0;
      } else {
        this.sidebarWidth = 250; // 或者您之前设置的宽度
      }
    },
    toggleMainSidebar() {
      this.showMainSidebar = !this.showMainSidebar;
    },
    Load(enterpriseId) {
      //用enterpriseId查询企业信息
      getEntDetail(enterpriseId).then(res => {
        const data = res.data;
        if (data.success) {
          this.enterpriseDetail = data.data;
        }
      });
    },
    triggerAddChat(name,_callback) {
      console.log('1203')
      console.log('triggerAddChat',name,_callback)
      this.$refs.projectTags.addChat(name,_callback);
     
    },
    //打开菜单
    openMenu(item = {}) {
      // 如果项目主页，则不打开菜单
      /*
      this.$store
        .dispatch('GetMenu', {projectId:item.projectId, enterpriseId: item.enterpriseId })
        .then(data => {
          if (data.length !== 0) {
            this.$router.$avueRouter.formatRoutes(data, true);
          }
        });
        */
        const tag = this.projectTagWel
        tag.query = {
          projectId:this.projectId,
          enterpriseId:this.enterpriseId
        }
        const { name, fullPath } = this.$route  
        tag.fullPath = fullPath
        this.$store.commit('ADD_PROJECT_TAG', {
                projectId: this.projectId,
                tag: tag,
              });
    },
    // 选中状态的数据组合方式，可以根据实际情况更改
    compositionString(item, i) {
      return item.label + '' + i.name;
    },

    // 判断是否选中
    isActive(item, i) {
      return this.compositionString(item, i) === this.historySelected;
    },
    toggleChatWindow() {
      this.showChatWindow = !this.showChatWindow;
      if (!this.showChatWindow) {
        this.selectedChat = null;
      }
    },
    // 点击后执行函数，调取数据
    getChatHistory(item, session) {
      /* this.historySelected = this.compositionString(item, session);
      console.log('this.$refs.currentComponent', this.$refs.currentComponent.$refs.chatWindow)
      this.$refs.currentComponent.$refs.chatWindow.getHistory(session); */
      // this.sidebarWidth = 800 //对话框宽度
      this.selectedChat = { item, session };
      
      this.showChatWindow = true;
      nextTick(() => {
        if (this.$refs.chatWindow) { 
          this.$refs.chatWindow.getHistory(item, session);
        }
      });
    },
  },
  setup() {
    const currentComponent = ref(null)

    onMounted(() => {
      console.log('Current route component:', currentComponent.value)
    })

    return { currentComponent }
  }
};
</script>
<style lang="scss" scoped>
.project-title{
  color: #333;
  background-image: none!important;
  line-height: 50px;
  margin-bottom: 30px;
  width: 200px;
  font-size: 18px;
  padding-left: 20px;
}
.project {
  .creat {
    background-color: #cfddf7;
  }
  ul li div.delete {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    background-color: #fff;
    color: #666;
    display: none;
    transition: 0.3s;
  }
  ul li:hover div.delete {
    display: flex;
  }
  ul li i:hover {
    color: #006cff;
    cursor: pointer;
  }
}
.sidebar-collapsed {
  width: 0 !important;
  overflow: hidden;
}
.pointer {
  cursor: pointer;
}
.saber-relative .active {
  background-color: #f1f1f1;
  padding: 0 5px;
  border-radius: 5px;
}
.sidebar-container {
  display: flex;
  position: relative; /* 为绝对定位的子元素提供参考 */
}
.project-layout {
  display: flex;
  height: 100vh; /* 使用视口高度 */
}
.main-sidebar {
  margin-left: 64px; /* 与小侧边栏的宽度相同 */
  background-color: #eff0fb!important;
}

.main-content {
  flex-grow: 1;
  overflow-y: auto; /* 允许内容滚动 */
}
.small-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 64px; /* 或者您希望的宽度 */
  z-index: 10; /* 确保小侧边栏在其他元素之上 */
  background-color: #f0f0f0; /* 根据需要调整背景色 */
 
}
.icon { 
  background-image: url('/img/icon/21信息.png'); /* 替换为您的背景图片路径 */ 
}
 
::v-deep .ai-sidebar .el-menu-item.is-active:before,::v-deep  .ai-sidebar .el-sub-menu__title.is-active:before {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    width: 0px!important;
    background: #409eff;
    position: absolute;
}
.avue-logo {
    height: 50px;
    line-height: 50px;
    background-color: #fff!important;
    font-size: 20px;
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15)!important;
    color: #fff!important;
    border: #8e9fac;
}
.main-sidebar{
  width: 280px;
}
.ai-sidebar{
  background-color: #fcfbfd!important;
  color: #8e9fac!important;
  width: 280px!important;
}
::v-deep .ai-sidebar .el-menu-item.is-active,::v-deep .ai-sidebar .el-sub-menu__title.is-active {
    background-color: #fff!important;
    padding: 0px;  
    line-height: 20px!important;
    height: 40px!important;
    border-radius: 10px!important;
    color: #409EFF!important; 
    border: 1px solid #409EFF !important;
    background: #fff;
    margin: 0px 20px;
    border-radius: 5px;
}

 

::v-deep .ai-sidebar .el-menu-item.is-active > i,
::v-deep .ai-sidebar .el-sub-menu__title.is-active > i {
  margin-left: -20px;
}
::v-deep .ai-sidebar .el-menu-item i, ::v-deep  .ai-sidebar .el-menu-item span,
::v-deep  .ai-sidebar .el-sub-menu__title i,::v-deep  .ai-sidebar .el-sub-menu__title span{
  color: #516574;
}
::v-deep .ai-sidebar .el-menu-item:hover i, 
::v-deep .ai-sidebar .el-menu-item:hover span,
::v-deep .ai-sidebar .el-sub-menu__title:hover i,
::v-deep .ai-sidebar .el-sub-menu__title:hover span {
  color: #1E90FF; /* 您想要的悬停颜色color: #8A2BE2; /* BlueViolet */  
}
::v-deep .ai-sidebar .el-menu-item.is-active i,::v-deep .ai-sidebar .el-menu-item.is-active span,::v-deep  .ai-sidebar .el-sub-menu__title.is-active i, .avue-sidebar .el-sub-menu__title.is-active span {
    color: #1E90FF!important;;
}
::v-deep  .el-dropdown-menu__item--divided:before,::v-deep  .el-menu, .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover, .el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  color: #272323!important;;
}
::v-deep .luckysheet-sta-c{ /**luckysheet 状态栏 */
  /*display: none;*/
}
.layout-container {
  position: relative;
  display: flex;
}
.sidebar-container {
  position: relative;
  transition: width 0s;
}

.resize-handle {
  position: absolute;
  top: 0;
  /* right: 15px; 调整位置，使其跨越边界 */
  width: 5px; /* 增加宽度，使其更容易点击 */
  height: 100%;
  cursor: ew-resize; /* 使用 ew-resize 来显示左右移动的光标 */
  background-color: transparent; /* 使背景透明 */
  transition: background-color 0.3s;
}

.resize-handle:hover {
  background-color: #c6e2ff; /* 悬停时显示的颜色 */
}

.resize-handle::after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px; /* 居中放置 */
  width: 1px; /* 默认宽度 */
  height: 100%;
  background-color: #dcdfe6;
  transition: width 0s, background-color 0s;
}

.resize-handle:hover::after {
  width: 3px; /* 悬停时变粗 */
  background-color: #c6e2ff; /* 悬停时的颜色 */
}

.toggle-sidebar {
  position: absolute;
  display: none;
  top: 50%;
  right: 20px;
  width: 20px;
  height: 50px;
  background-color: #409EFF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.el-aside {
  transition: width 0s;
  height: 100%;
}
.sidebar-container{
  height: 100%;
}
.chat-window {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

::v-deep el-loading-mask{
  margin-right: 40px;
}
</style>
 

