import request from '@/axios';

const baseUrl = import.meta.env.VITE_APP_API_PYTHON|| '/pythonapi';
export const createChatSession = (projectId, title, additionalFields = {},info_json = {}) => {
  return request({
    url: baseUrl+'/chatsessionapi/chat-sessions',
    method: 'post',
    data:{
        "chat_session": {
          // ChatSession 的字段
          project_id: projectId,
          session_name: title,
          ...additionalFields
        },
        "info_json": {
          // 可选的额外 JSON 信息
          ...info_json
        }
    } 
  });
};

// 获取聊天会话列表
export const getChatSessions = (projectId) => {
    return request({
      url: `${baseUrl}/chatsessionapi/chat-sessions`,
      method: 'get',
      params: { project_id: projectId ,limit:100}
    });
  };

// 获取特定业务类型的聊天会话列表
export const getChatSessionsByBizCode = (projectId, bizCode, limit = 10, offset = 0) => {
  return request({
    url: `${baseUrl}/chatsessionapi/chat-sessions-biz`,
    method: 'get',
    params: {
      project_id: projectId,
      biz_code: bizCode, /* 业务类型  增值税申报表提取 zzs_tax_pdf*/
      limit,
      offset
    }
  });
};
  
// 获取特定聊天会话
export const getChatSession = (sessionId) => {
    return request({
        url: `${baseUrl}/chatsessionapi/chat-sessions/${sessionId}`,
        method: 'get'
    });
};

// 删除聊天会话
export const deleteChatSession = (sessionId) => {
    return request({
        url: `${baseUrl}/chatsessionapi/chat-sessions/${sessionId}`,
        method: 'delete'
    });
};

//新增message
// 获取聊天消息
export const getChatMessages = (sessionId) => {
  return request({
    url: `${baseUrl}/chatsessionapi/chat-messages`,
    method: 'get',
    params: { session_id: sessionId }
  });
};

// 新增聊天消息
export const createChatMessage = (sessionId, content) => {
  return request({
    url: `${baseUrl}/chatsessionapi/chat-messages`,
    method: 'post',
    data: {
      session_id: sessionId,
      ...content
    }
  });
};

//获得空白表格格式
export const gen_table_struction = (filelink, sheetName) => {
  return request({
    url: `${baseUrl}/chatapi/gen_table_struction`,
    method: 'get',
    params: {
      filelink: filelink,
      sheetName: sheetName
    }
  });
};

export const save_user_customformat_table = (project_id,selectedRows) => {
  return request({
    url: `${baseUrl}/api/subject/save_user_subject_categories`,
    method: 'post',
    data: {
      project_id: project_id ,
      selectedRows: selectedRows
    }
  });
};

export const addToVoucherCheck = (data) => {
  return request({
    url:  `${baseUrl}/api/subject/addToVoucherCheck`,
    method: 'post',
    data
  })
}


export const getTableInfo = (fileId, originalFileName) => {
  return request({
    url: `${baseUrl}/api/subject/get_table_info`,
    method: 'get',
    params: {
      fileid: fileId,
      originalFileName: originalFileName
    }
  });
};

export const getDictionary = (code) => {
  return request({
    url: '/sc-system/dict/dictionary',
    method: 'get',
    params: {
      code
    }
  });
};
export const gen_table_csvformat = (project_id,enterprise_id,year,gentype) => {
  return request({
    url: `${baseUrl}/api/subject/compile_financial_statements`,
    method: 'get',
    params: {
      project_id: project_id ,
      enterprise_id: enterprise_id,
      year: year,
      gentype: gentype
    }
  });
};


export const getAnalysisExtra = (projectId, name, type) => {
  return request({
    url: `${baseUrl}/api/subject/get_analysis_extra`,
    method: 'get',
    params: {
      projectId,
      name,
      type
    }
  });
};

export const getRandomQA = (query) => {
  return request({
    url: `${baseUrl}/chatapi/random_qa`,
    method: 'get',
    params: {
      query: query.query
    }
  });
};

export const runFlowApi = `${baseUrl}/chatapi/run`
export const taxQAUrl = `${baseUrl}/chatapi/tax_qa`

export const randomQA = `${baseUrl}/chatapi/random_qa`